import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import IphoneStatus from '../icons/Iphone.svg';
import ArrowLeftStatus from '../icons/arrow/left.svg';
import TitleBold from '../components/Fonts/TitleBold';
import Post from '../components/Post';
import landingAccount from '../helpers/landingAccount.json';
import landingPosts from '../helpers/landingPosts.json';
import Profile from '../components/Profile';
import ProgressiveImage from '../components/ProgressiveImage';
import { getPhotoSource } from '../helpers/axios';
import H1 from '../components/Fonts/H1';
import MainText from '../components/Fonts/MainText';
import LogoIcon from '../icons/logo.svg';
import DownloadBlock from '../components/DownloadBlock';
import Link from 'next/link';

const Home: NextPage = () => {
  const [time, setTime] = useState(dayjs().format('H:mm'));
  const [scale, setScale] = useState(0);
  const [secondActive, setSecondActive] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const func = () => {
      setTime(dayjs().format('H:mm'));
      timeout = setTimeout(() => {
        func();
      }, 60000 - (Number(new Date()) % 60000));
    };
    func();

    const resize = () => {
      setScale(
        Math.min(
          window.innerWidth <= 700 ? 10 : window.innerHeight / 924,
          window.innerWidth / (window.innerWidth <= 700 ? 470 : 1000),
        ),
      );
    };

    window.addEventListener('resize', resize, { passive: true });

    resize();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Реки</title>
        <meta name="description" content="Новая социальная сеть. Аналог Instagram" />
        <link rel="canonical" href="https://rekee.ru" />
      </Head>
      <div className={styles.container}>
        <div className={styles.phoneContainer}>
          <div
            className={styles.phone}
            style={{
              transform: `scale(${scale})`,
              margin: `${447 * (scale - 1)}px ${218.5 * (scale - 1)}px`,
            }}
          >
            <div className={styles.inner}>
              <div
                className={styles.screen}
                style={{ filter: `brightness(${secondActive ? 0.5 : 1})` }}
              >
                <div className={styles.header}>
                  <TitleBold>cats</TitleBold>
                </div>
                <Profile
                  account={landingAccount}
                  username={landingAccount.username}
                  avatar={landingAccount.avatar}
                />
                <button className={styles.postsGrid} onClick={() => setSecondActive(true)}>
                  {landingPosts.map(post => (
                    <ProgressiveImage
                      key={post._id}
                      width={130}
                      height={130}
                      src={getPhotoSource(post.miniature)}
                      thumbnailUri={post.miniatureThumbnail}
                    />
                  ))}
                </button>
              </div>
              <div
                className={`${styles.screen} ${styles.screen2}`}
                style={{ transform: `translate(${secondActive ? 0 : 100}%)` }}
              >
                <div className={styles.header}>
                  <button onClick={() => setSecondActive(false)} className={styles.back}>
                    <ArrowLeftStatus />
                  </button>
                  <TitleBold>cats</TitleBold>
                </div>
                <div className={styles.posts}>
                  {landingPosts.map(post => (
                    // @ts-expect-error: ругается на string
                    <Post key={post._id} width={373} post={{ ...post, account: landingAccount }} />
                  ))}
                </div>
              </div>
              <div className={styles.statusBar}>
                <p className={styles.clock}>{time}</p>
                <IphoneStatus />
              </div>
            </div>
            <div className={styles.image}>
              <Image src="/iphone.png" width={437} height={890} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.logo}>
            <LogoIcon height={58} />
          </div>
          <H1>Новая социальная сеть, с новым названием</H1>
          <MainText className={styles.description}>
            Делитесь лучшими моментами своей жизни, обсуждайте их со своими друзьями, родными и
            близкими.
          </MainText>
          <MainText className={styles.description}>
            В рекомендациях вы найдёте безграничный контент, основанный на ваших интересах.
          </MainText>
          <MainText className={styles.description}>Добро пожаловать!</MainText>
          <DownloadBlock />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <Link href="/privacy-policy">
            <a>Пользовательское соглашение</a>
          </Link>
          <Link href="/limbiko">
            <a>Что с Лимбико?</a>
          </Link>
        </div>

        <div className={styles.cookie}>
          <div>
            Реки использует файлы cookie. Используя сайт rekee.ru, вы автоматически соглашаетесь с
            Политикой обработки данных
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
