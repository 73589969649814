import React from 'react';
import { getPhotoSource } from '../helpers/axios';
import Button from './Button';
import SmallText from './Fonts/SmallText';
import SmallTextPostBold from './Fonts/SmallTextPostBold';
import TitleBold from './Fonts/TitleBold';
import ProgressiveImage from './ProgressiveImage';
import FormattedText from './FormattedText';
import styles from './Profile.module.scss';
import Account from '../types/Account';

export interface ProfileRef {
  update: () => Promise<void>;
}

interface ProfileProps {
  username: string;
  avatar?: string;
  account: Partial<Account>;
}

const defaultProps = {
  avatar: '',
  navigation: null,
};

const Profile = ({ username, avatar, account }: ProfileProps) => {
  return (
    <div className={styles.main}>
      <div className={styles.avatarBlock}>
        <ProgressiveImage
          // @ts-expect-error: TODO
          text={account?.username || username}
          value={account?._id}
          width={80}
          height={80}
          thumbnailUri={account?.avatarThumbnail}
          containerClassName={styles.avatar}
          src={getPhotoSource(account?.avatar || avatar)}
        />
        <div className={styles.textBlock}>
          <TitleBold>{account?.subscribersNumber}</TitleBold>
          <SmallText className={styles.textBlockSubtitle}>Подписчики</SmallText>
        </div>
        <div className={styles.textBlock}>
          <TitleBold>{account?.subscribtionsNumber}</TitleBold>
          <SmallText className={styles.textBlockSubtitle}>Подписки</SmallText>
        </div>
      </div>
      {account?.name ? (
        <SmallTextPostBold className={styles.name}>{account.name || ''}</SmallTextPostBold>
      ) : null}
      {account?.bio ? (
        <SmallText className={styles.bio}>
          <FormattedText text={account.bio} />
        </SmallText>
      ) : null}
      <Button className={styles.edit}>Подписаться</Button>
    </div>
  );
};

Profile.defaultProps = defaultProps;

export default Profile;
