import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styles from './Button.module.scss';
import Spinner from './Spinner';

interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean;
  round?: boolean;
}

const Button = ({ children, className = '', loading, round, ...props }: ButtonProps) => {
  return (
    <button className={`${styles.main} ${round ? styles.round : ''} ${className}`} {...props}>
      {loading ? <Spinner className={styles.loading} /> : children}
    </button>
  );
};

export default Button;
