import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './H1.module.scss';

const H1 = ({
  className = '',
  children,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) => (
  <h1 className={`${styles.main} ${className}`} {...props}>
    {children}
  </h1>
);

export default H1;
